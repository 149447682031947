import { HomeState, SalesProcessStatus } from '../generated/backend/graphql';

export const UPDATE_HOME_FILTER = 'UPDATE_HOME_FILTER';

export type HomeFilter = {
  dateSection: boolean;
  createdDateEnabled: boolean;
  createdDateFrom: string | undefined;
  createdDateTo: string | undefined;
  agreementDateEnabled: boolean;
  agreementDateFrom: string | undefined;
  agreementDateTo: string | undefined;
  contractDateEnabled: boolean;
  contractDateFrom: string | undefined;
  contractDateTo: string | undefined;
  withdrawalDateEnabled: boolean;
  withdrawalDateFrom: string | undefined;
  withdrawalDateTo: string | undefined;
  hasAssignedAgentEnabled: boolean;
  hasAssignedAgentInverted: boolean;
  assignedAgentId: string | null;
  homeStatesEnabled: boolean;
  homeStates: boolean[];
  salesProcessStatusEnabled: boolean;
  salesProcessStatus: boolean[];
};

const defaultState: HomeFilter = {
  dateSection: true,
  createdDateEnabled: false,
  createdDateFrom: undefined,
  createdDateTo: undefined,
  agreementDateEnabled: false,
  agreementDateFrom: undefined,
  agreementDateTo: undefined,
  contractDateEnabled: false,
  contractDateFrom: undefined,
  contractDateTo: undefined,
  withdrawalDateEnabled: false,
  withdrawalDateFrom: undefined,
  withdrawalDateTo: undefined,
  hasAssignedAgentEnabled: false,
  hasAssignedAgentInverted: false,
  assignedAgentId: null,
  homeStatesEnabled: false,
  homeStates: Object.keys(HomeState).map(() => true),
  salesProcessStatusEnabled: false,
  salesProcessStatus: Object.keys(SalesProcessStatus).map(() => true),
};

export default (
  state = defaultState,
  action: { type: string; filter: HomeFilter } = {
    type: '',
    filter: defaultState,
  },
) => {
  switch (action.type) {
    case UPDATE_HOME_FILTER: {
      return {
        ...state,
        ...action.filter,
      };
    }

    default:
      return state;
  }
};
