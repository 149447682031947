type Viewing = {
  id: string;
  startsAt?: string | null;
  endsAt?: string | null;
  nbrOfBooked?: number | null;
  maxNbrOfParticipants?: number | null;
  unspecifiedTime?: boolean | null;
};

// Viewings should always be rendered in the local timezone of the viewing.
// This is always Europe/Stockholm (for now).
const viewingTimeZone = 'Europe/Stockholm';

const formatTime = (time?: string | null) => {
  if (!time) {
    return undefined;
  }

  return new Intl.DateTimeFormat('sv-SE', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: viewingTimeZone,
  }).format(new Date(time));
};

export function formatViewingDay(viewing: Viewing) {
  if (!viewing.startsAt) {
    return undefined;
  }

  return new Intl.DateTimeFormat('sv-SE', {
    weekday: 'long',
    timeZone: viewingTimeZone,
  }).format(new Date(viewing.startsAt));
}

export function formatViewingDate(viewing: Viewing) {
  if (!viewing.startsAt) {
    return undefined;
  }

  return new Intl.DateTimeFormat('sv-SE', {
    month: 'short',
    day: 'numeric',
    timeZone: viewingTimeZone,
  }).format(new Date(viewing.startsAt));
}

export const formatViewingDateLong = (date?: string | null) => {
  if (!date) {
    return undefined;
  }

  return new Intl.DateTimeFormat('sv-SE', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: viewingTimeZone,
  }).format(new Date(date));
};

export function formatViewingTime(viewing: Viewing) {
  if (!viewing.startsAt || viewing.unspecifiedTime) {
    return undefined;
  }

  const formattedTimes = [
    formatTime(viewing.startsAt),
    formatTime(viewing.endsAt),
  ].filter(formattedTime => formattedTime);

  return formattedTimes.join(' - ');
}

export function formatStartDateAndTime(viewing: Viewing) {
  const { startsAt, unspecifiedTime } = viewing;

  if (!startsAt) {
    return undefined;
  }

  const dateAndTimeString = new Intl.DateTimeFormat('sv-SE', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: !unspecifiedTime ? 'numeric' : undefined,
    minute: !unspecifiedTime ? 'numeric' : undefined,
    timeZone: viewingTimeZone,
  }).format(new Date(startsAt));

  return dateAndTimeString.charAt(0).toUpperCase() + dateAndTimeString.slice(1);
}

export function upcomingViewingDates<T extends Viewing>(viewings: T[]) {
  const now = new Date();
  return (viewings ?? [])
    .filter(viewing => viewing.endsAt && new Date(viewing.endsAt) > now)
    .sort(
      (a, b) =>
        new Date(a.endsAt ?? '').getTime() - new Date(b.endsAt ?? '').getTime(),
    );
}

export const viewingIsFull = (viewing: Viewing) =>
  typeof viewing?.maxNbrOfParticipants === 'number' &&
  typeof viewing.nbrOfBooked === 'number' &&
  viewing.nbrOfBooked > 0 &&
  viewing.maxNbrOfParticipants > 0 &&
  viewing.nbrOfBooked >= viewing.maxNbrOfParticipants;
