import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ClearOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import {
  formatNumber,
  formatViewingDate,
  formatViewingTime,
  generateDefaultImage,
  labelColor,
  upcomingViewingDates,
  viewingIsFull,
} from '@norban/utils';
import { formatViewingDay } from '@norban/utils/dist/home/viewing';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CONTENT_URI, WEB_URI } from '../config';
import {
  BofEmailDialogLookupAgentDocument,
  BofEmailDialogLookupHomeDocument,
  BofEmailDialogViewingFragment,
  BofSendBulkEmailDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
  HomeType,
  UserFilter,
} from '../generated/backend/graphql';
import usePopupAlert from '../hooks/usePopupAlert';

import HomeLookup from './HomeLookup';
import WizardDialog from './WizardDialog';

interface Recipient {
  id: string;
  email: string;
}

type Props<T extends Recipient> = {
  defaultType?: BulkEmailType;
  recipients: T[] | undefined;
  homeId?: number;
  open: boolean;
  typeDatas: {
    type: BulkEmailType;
    unsubscribeType: BulkEmailUnsubscribeType;
    description: string;
    subject?: string;
    message?: string;
    signatureType?: string;
  }[];
  userFilter: UserFilter | undefined;
  userFilterCount: number;
  skipFirstTwoSteps?: boolean;
  bodySubstitutions?: { regex: RegExp; value: string }[];
  assignedAgentId?: string;
  onClose: () => void;
  onSent: () => void;
  onOverrideSend?: ({
    subject,
    body,
  }: {
    subject: string;
    body: string;
  }) => void;
};

const WARNING_LIMIT = 100;

const useStyles = makeStyles(() => ({
  iframe: {
    height: 320,
    width: '100%',
  },
}));

const typeToTemplateMap = {
  [BulkEmailType.Custom]: 'bulk-email',
  [BulkEmailType.FollowerCustomWithHome]: 'custom-with-home',
  [BulkEmailType.FollowerGenericUpdate]: 'generic-update',
  [BulkEmailType.FollowerNowOnPremarket]: 'now-on-premarket',
  [BulkEmailType.FollowerPhotosPublished]: 'photos-published',
  [BulkEmailType.FollowerUpdate]: 'follower-update',
  [BulkEmailType.FollowerViewingScheduled]: 'viewing-scheduled',
  [BulkEmailType.FollowerRecommendAHome]: 'recommend-a-home',
  [BulkEmailType.MarketingSoldWithoutPrice]: 'marketing-sold-home',
  [BulkEmailType.MarketingSoldWithPrice]: 'marketing-sold-home',
  [BulkEmailType.MatchingHomes]: 'matching-homes',
  [BulkEmailType.FollowerSoldUpdate]: 'follower-sold-update',
  [BulkEmailType.CampaignFollowers_2024Mar]: 'campaign-followers-2024-mar',
  [BulkEmailType.LeadTemplateOnboarding]: 'lead-template-onboarding',
  [BulkEmailType.LeadTemplateInterest]: 'lead-template-interest',
  [BulkEmailType.LeadTemplateSms]: 'No template!',
  [BulkEmailType.LeadTemplateSms2]: 'No template!',
  [BulkEmailType.LeadTemplateInterest2]: 'lead-template-interest2',
  [BulkEmailType.LeadTemplateInterest3]: 'lead-template-interest3',
  [BulkEmailType.LeadTemplateHidden]: 'lead-template-hidden',
  [BulkEmailType.LeadTemplateFollower]: 'lead-template-follower',
  [BulkEmailType.LeadTemplateTagYourHome]: 'lead-template-tag-your-home',
  [BulkEmailType.LeadTemplateBecomeFollower]: 'lead-template-become-follower',
  [BulkEmailType.LeadTemplateFloorPlan]: 'lead-template-floor-plan',
  [BulkEmailType.LeadTemplateUploadPhotos]: 'lead-template-upload-photos',
  [BulkEmailType.LeadTemplateCampaign]: 'lead-template-campaign',
  [BulkEmailType.AgreementContractActivation]: 'agreement-contract-activate',
};

const EmailDialog = <T extends Recipient>({
  defaultType = BulkEmailType.Custom,
  recipients,
  homeId = undefined,
  open,
  typeDatas,
  userFilter,
  userFilterCount,
  skipFirstTwoSteps = false,
  bodySubstitutions = undefined,
  assignedAgentId = undefined,
  onClose,
  onSent,
  onOverrideSend = undefined,
}: Props<T>) => {
  const L = locale.backoffice;
  const LSubjects = locale.mail.subjects;
  const classes = useStyles();

  const [selectedType, setSelectedType] = useState<BulkEmailType | undefined>();
  const [recipientSubset, setRecipientSubset] = useState<'list' | 'filtered'>(
    'list',
  );
  const [lookupWarning, setLookupWarning] = useState('');
  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const currentType = selectedType ?? defaultType;

  const selectedUnsubscribeType = useMemo(() => {
    const typeData = typeDatas.find(template => template.type === currentType);
    return typeData?.unsubscribeType ?? BulkEmailUnsubscribeType.Marketing;
  }, [currentType, typeDatas]);
  const selectedSignatureType = useMemo(() => {
    const typeData = typeDatas.find(template => template.type === currentType);
    return typeData?.signatureType ?? 'withLogo';
  }, [currentType, typeDatas]);
  const getTypeData = useCallback(
    (aCategory: BulkEmailType) =>
      typeDatas.find(template => template.type === aCategory),
    [typeDatas],
  );
  const currentTypeData = getTypeData(currentType);
  const defaultMessage = currentTypeData?.message ?? '';

  const [modifiedSubject, setModifiedSubject] = useState<string | undefined>(
    undefined,
  );
  const [body, setBody] = useState<string | undefined>(undefined);
  const currentBody = body ?? defaultMessage;

  const [modifiedHomeId, setModifiedHomeId] = useState<number | undefined>(
    homeId,
  );
  const currentHomeId = modifiedHomeId ?? homeId;

  const selectedUserFilter = useMemo(() => {
    if (recipientSubset === 'list') {
      return { ids: recipients?.map(recipient => recipient.id) ?? [] };
    }

    return userFilter ?? { ids: [] };
  }, [recipients, recipientSubset, userFilter]);
  const recipientCount = useMemo(() => {
    if (recipientSubset === 'list') {
      return recipients?.length ?? 0;
    }
    return userFilterCount;
  }, [recipients?.length, recipientSubset, userFilterCount]);

  const templateName = currentTypeData?.type
    ? typeToTemplateMap[currentTypeData.type]
    : undefined;

  const localeSubject =
    templateName !== undefined && templateName in LSubjects
      ? LSubjects[templateName as keyof typeof LSubjects].subject
      : undefined;
  const defaultSubject = currentTypeData?.subject ?? localeSubject ?? '';
  const currentSubject = modifiedSubject ?? defaultSubject;

  // FIXME - remove and reactively render the lookup data
  const processTemplate = useCallback(
    (
      type: BulkEmailType,
      address: string | undefined,
      viewingStartsAt: string | undefined,
      agentName: string | undefined,
      salesBullets: string | undefined,
      area: string | undefined,
      homeType: HomeType | undefined,
      livingArea: number | undefined,
      numberOfRooms: number | undefined,
      closingPrice: number | undefined,
    ) => {
      if (type === BulkEmailType.Custom) {
        setLookupWarning('');
        setBody(undefined);
        setModifiedSubject(undefined);
      } else {
        const template = getTypeData(type);

        let templateMessage = template?.message ?? '';
        const templateName = template?.type
          ? typeToTemplateMap[template.type]
          : undefined;

        let templateTitle =
          templateName !== undefined && templateName in LSubjects
            ? LSubjects[templateName as keyof typeof LSubjects].subject
            : undefined;

        const missingAddress =
          templateMessage.includes('{address}') && !address;
        const missingTime =
          templateMessage.includes('{time}') && !viewingStartsAt;
        const missingAgentName =
          templateMessage.includes('{agentName}') && !agentName;
        const missingSalesBullets =
          templateMessage.includes('{salesBullets}') && !salesBullets;
        const missingArea = templateMessage.includes('{area}') && !area;
        const missingHomeType =
          templateMessage.includes('{homeType}') && !homeType;
        const missingLivingArea =
          templateMessage.includes('{livingArea}') && !livingArea;
        const missingNumberOfRooms =
          templateMessage.includes('{numberOfRooms}') && !numberOfRooms;
        const missingSoldHomeProperties =
          templateMessage.includes('{soldHomeProperties}') &&
          (!homeType || !livingArea || !numberOfRooms);
        const missingPriceInformation =
          type !== BulkEmailType.MarketingSoldWithoutPrice &&
          templateMessage.includes('{priceInformation}') &&
          (!closingPrice || !livingArea);
        const missingSoldOnPremarketOrNot =
          type === BulkEmailType.MarketingSoldWithoutPrice ||
          type === BulkEmailType.MarketingSoldWithPrice;
        const missingAreaPreposition =
          type === BulkEmailType.MarketingSoldWithoutPrice ||
          type === BulkEmailType.MarketingSoldWithPrice;

        if (
          missingAddress ||
          missingTime ||
          missingAgentName ||
          missingSalesBullets ||
          missingArea ||
          missingHomeType ||
          missingLivingArea ||
          missingNumberOfRooms ||
          missingSoldHomeProperties ||
          missingPriceInformation ||
          missingSoldOnPremarketOrNot ||
          missingAreaPreposition
        ) {
          // If the completed the lookup is incomplete, set the warning to inform the user about the missing information

          setLookupWarning(
            `Följande information för mallen saknas för denna bostad: ${[
              missingAddress ? 'adress' : undefined,
              missingTime ? 'visningstid' : undefined,
              missingAgentName ? 'mäklare' : undefined,
              missingSalesBullets ? 'text till spekulanter' : undefined,
              missingArea ? 'område' : undefined,
              missingHomeType ? 'bostadstyp' : undefined,
              missingLivingArea ? 'boarea' : undefined,
              missingNumberOfRooms ? 'antal rum' : undefined,
              missingSoldHomeProperties
                ? 'bostadstyp, boarea eller antal rum (bostadsinformation för såld bostad)'
                : undefined,
              missingPriceInformation
                ? 'slutpris eller boarea (pris per kvadratmeter)'
                : undefined,
              missingSoldOnPremarketOrNot
                ? 'såld på Premarket eller inte'
                : undefined,
              missingAreaPreposition
                ? 'preposition (i eller på) området'
                : undefined,
            ]
              .filter(Boolean)
              .join(', ')}`,
          );
        } else {
          setLookupWarning('');
        }

        if (type === BulkEmailType.MarketingSoldWithoutPrice) {
          templateMessage = templateMessage.replace(
            /\{priceInformation\}/g,
            '',
          );
        } else {
          const price = closingPrice ? formatNumber(closingPrice) : undefined;
          const averagePrice =
            closingPrice && livingArea
              ? formatNumber(Math.round(closingPrice / livingArea))
              : undefined;

          templateMessage = templateMessage.replace(
            /\{priceInformation\}/g,
            ` Slutpriset blev ${price ?? L.emailDialog.closingPriceDefault} kr (${averagePrice ?? L.emailDialog.averagePriceDefault} kr/kvm), ett väldigt bra pris jämfört med tillgänglig statistik.`,
          );
        }

        if (address) {
          templateMessage = templateMessage.replace(/\{address\}/g, address);
          templateTitle = templateTitle?.replace(/\{address\}/g, address);
        } else {
          templateMessage = templateMessage.replace(
            /\{address\}/g,
            L.emailDialog.addressDefault,
          );
          templateTitle = templateTitle?.replace(
            /\{address\}/g,
            L.emailDialog.addressDefault,
          );
        }
        if (viewingStartsAt) {
          templateMessage = templateMessage.replace(
            /\{time\}/g,
            viewingStartsAt,
          );
        } else {
          templateMessage = templateMessage.replace(
            /\{time\}/g,
            L.emailDialog.timeDefault,
          );
        }
        if (agentName) {
          templateMessage = templateMessage.replace(
            /\{agentName\}/g,
            agentName,
          );
        } else {
          templateMessage = templateMessage.replace(
            /\{agentName\}/g,
            L.emailDialog.agentNameDefault,
          );
        }
        if (salesBullets) {
          templateMessage = templateMessage.replace(
            /\{salesBullets\}/g,
            // Trim trailing newlines
            salesBullets.replace(/\n+$/, ''),
          );
        } else {
          templateMessage = templateMessage.replace(
            /\{salesBullets\}/g,
            L.emailDialog.salesBulletsDefault,
          );
        }
        if (area) {
          templateMessage = templateMessage.replace(/\{area\}/g, area);
        } else {
          templateMessage = templateMessage.replace(
            /\{area\}/g,
            L.emailDialog.areaDefault,
          );
        }
        if (homeType && livingArea && numberOfRooms) {
          const apartment = homeType === HomeType.Apartment;
          const soldHomeProperties = apartment
            ? `en fin ${numberOfRooms}:a på ${livingArea} kvm`
            : `ett fint hus på ${livingArea} kvm`;
          templateMessage = templateMessage.replace(
            /\{soldHomeProperties\}/g,
            soldHomeProperties,
          );
        } else {
          templateMessage = templateMessage.replace(
            /\{soldHomeProperties\}/g,
            L.emailDialog.soldHomePropertiesDefault,
          );
        }
        bodySubstitutions?.forEach(({ regex, value }) => {
          templateMessage = templateMessage.replace(regex, value);
        });

        setBody(templateMessage);
        setModifiedSubject(templateTitle);
      }
    },
    [
      getTypeData,
      LSubjects,
      bodySubstitutions,
      L.emailDialog.closingPriceDefault,
      L.emailDialog.averagePriceDefault,
      L.emailDialog.addressDefault,
      L.emailDialog.timeDefault,
      L.emailDialog.agentNameDefault,
      L.emailDialog.salesBulletsDefault,
      L.emailDialog.areaDefault,
      L.emailDialog.soldHomePropertiesDefault,
    ],
  );

  const upcomingViewingTime = useCallback(
    (viewings?: BofEmailDialogViewingFragment[]) => {
      const nextViewing = upcomingViewingDates(viewings ?? []).filter(
        viewing => !viewingIsFull(viewing),
      )[0];

      if (!nextViewing) {
        return '';
      }

      const viewingDay = formatViewingDay(nextViewing);
      const viewingDate = formatViewingDate(nextViewing);
      const viewingTime = formatViewingTime(nextViewing);

      if (!viewingDay || !viewingDate || !viewingTime) {
        return '';
      }

      return `${viewingDay} den ${viewingDate} kl ${viewingTime}`;
    },
    [],
  );

  const { data: lookupData, loading: lookupLoading } = useQuery(
    BofEmailDialogLookupHomeDocument,
    {
      variables: {
        homeId: currentHomeId ? `${currentHomeId}` : '',
      },
      skip: !currentHomeId,
      onError: () => {
        setLookupWarning('Misslyckades slå upp bostaden!');
      },
    },
  );

  const { data: lookupAgentData } = useQuery(
    BofEmailDialogLookupAgentDocument,
    {
      variables: {
        id: assignedAgentId ? `${assignedAgentId}` : '',
      },
      skip: !assignedAgentId,
    },
  );

  const currentAgent = lookupAgentData?.user ?? lookupData?.home?.assignedAgent;

  // Should perferably be done in a more reactive way (useMemo)
  useEffect(() => {
    const streetAddress = lookupData?.home?.address.publicStreetAddress;
    const time = upcomingViewingTime(lookupData?.home?.viewings);
    const agentName = currentAgent?.name ?? undefined;
    const salesBullets = lookupData?.home?.salesBullets ?? undefined;
    const area = lookupData?.home?.area?.name ?? undefined;
    const homeType = lookupData?.home?.type ?? undefined;
    const livingArea = lookupData?.home?.livingArea ?? undefined;
    const numberOfRooms = lookupData?.home?.numberOfRooms ?? undefined;
    const closingPrice = lookupData?.home?.closingPrice ?? undefined;

    processTemplate(
      currentType,
      streetAddress ?? undefined,
      time,
      agentName,
      salesBullets,
      area,
      homeType,
      livingArea,
      numberOfRooms,
      closingPrice,
    );
  }, [
    currentType,
    lookupData?.home?.address.publicStreetAddress,
    lookupData?.home?.area?.name,
    currentAgent?.name,
    lookupData?.home?.closingPrice,
    lookupData?.home?.livingArea,
    lookupData?.home?.numberOfRooms,
    lookupData?.home?.salesBullets,
    lookupData?.home?.type,
    lookupData?.home?.viewings,
    processTemplate,
    upcomingViewingTime,
  ]);

  const EmailPreview = useCallback(
    (props: { unsubscribeType: BulkEmailUnsubscribeType }) => {
      const { unsubscribeType } = props;

      const includePrice =
        currentType === BulkEmailType.MarketingSoldWithPrice &&
        lookupData?.home?.closingPrice;

      const uri = templateName
        ? new URL(`${CONTENT_URI}/email-template/sv/${templateName}.html`)
        : undefined;

      const signaturePhotoUrl =
        selectedSignatureType === 'withLogo'
          ? `${CONTENT_URI}/signature?url=${encodeURIComponent(currentAgent?.photoURL ?? '')}`
          : currentAgent?.photoURL;

      if (uri) {
        uri.searchParams.set(
          'variables',
          JSON.stringify({
            address: lookupData?.home.address.publicStreetAddress ?? '',
            area: lookupData?.home.area?.name ?? '',
            body: currentBody,
            image: lookupData?.home.publicHeroImage?.id
              ? `${CONTENT_URI}/images/h400@2x/${lookupData.home.publicHeroImage.id}.jpg`
              : `${WEB_URI}${generateDefaultImage(lookupData?.home.id, false)}`,
            link: '{link}',
            cardLink: '{cardLink}',
            state: lookupData?.home.state
              ? L.enumerations.HomeState[lookupData.home.state]
              : '',
            stateColor: lookupData?.home.state
              ? labelColor(lookupData.home.state)
              : '#000',
            signatureAvatar: signaturePhotoUrl,
            signatureName: currentAgent?.name,
            signaturePhone: currentAgent?.phone,
            signatureOfficeAddress: currentAgent?.office?.address,
            signatureOfficeCity: currentAgent?.office?.city,
            signatureLink: `${WEB_URI}`,
            unsubscribeLink: '{unsubscribeLink}',
            price: includePrice
              ? formatNumber(lookupData.home.closingPrice)
              : undefined,
            unsubscribeType,
          }),
        );
      }

      return (
        <>
          <Typography
            fontWeight="bold"
            variant="body1"
            display="block"
            gutterBottom
          >
            Ämnesrad: {currentSubject}
          </Typography>
          <br />
          <iframe
            className={classes.iframe}
            title="email-preview"
            src={uri ? uri.toString() : undefined}
          />
        </>
      );
    },
    [
      currentType,
      lookupData?.home.closingPrice,
      lookupData?.home.address.publicStreetAddress,
      lookupData?.home.area?.name,
      lookupData?.home.publicHeroImage?.id,
      lookupData?.home.id,
      lookupData?.home.state,
      templateName,
      selectedSignatureType,
      currentAgent?.photoURL,
      currentAgent?.name,
      currentAgent?.phone,
      currentAgent?.office?.address,
      currentAgent?.office?.city,
      currentSubject,
      classes.iframe,
      currentBody,
      L.enumerations.HomeState,
    ],
  );

  const resetAll = useCallback(() => {
    setBody(undefined);
    setModifiedSubject(undefined);

    const streetAddress = lookupData?.home?.address.publicStreetAddress;
    const time = upcomingViewingTime(lookupData?.home?.viewings);
    const agentName = currentAgent?.name ?? undefined;
    const salesBullets = lookupData?.home?.salesBullets ?? undefined;
    const area = lookupData?.home?.area?.name ?? undefined;
    const homeType = lookupData?.home?.type ?? undefined;
    const livingArea = lookupData?.home?.livingArea ?? undefined;
    const numberOfRooms = lookupData?.home?.numberOfRooms ?? undefined;
    const closingPrice = lookupData?.home?.closingPrice ?? undefined;

    processTemplate(
      defaultType,
      streetAddress ?? undefined,
      time,
      agentName,
      salesBullets,
      area,
      homeType,
      livingArea,
      numberOfRooms,
      closingPrice,
    );

    setSelectedType(undefined);
    setRecipientSubset('list');
    setLookupWarning('');
    setModifiedHomeId(undefined);
  }, [
    defaultType,
    lookupData?.home?.address.publicStreetAddress,
    lookupData?.home?.area?.name,
    currentAgent?.name,
    lookupData?.home?.closingPrice,
    lookupData?.home?.livingArea,
    lookupData?.home?.numberOfRooms,
    lookupData?.home?.salesBullets,
    lookupData?.home?.type,
    lookupData?.home?.viewings,
    processTemplate,
    upcomingViewingTime,
  ]);

  const [sendBulkEmail] = useMutation(BofSendBulkEmailDocument, {
    update: cache => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'logEntries',
      });
      cache.gc();
    },
    onCompleted: () => {
      resetAll();
      onSent();
      onClose();
    },
  });

  const handleSendMail = async () => {
    if (onOverrideSend) {
      onOverrideSend({
        subject: currentSubject,
        body: currentBody,
      });
      return;
    }

    try {
      await sendBulkEmail({
        variables: {
          template: currentType,
          userFilter: selectedUserFilter,
          subject: currentSubject,
          body: currentBody,
          unsubscribeType: selectedUnsubscribeType,
          homeId: currentHomeId ? `${currentHomeId}` : undefined,
          agentId: currentAgent?.id,
        },
      });
      showPopupAlert(L.sent, 'success');
    } catch (err) {
      const ae = err as ApolloError;
      showPopupAlert(ae.message, 'error');
    }
  };

  const handleClose = () => {
    resetAll();
    onClose();
  };

  const isValidEmail = useMemo(() => {
    if (currentType === BulkEmailType.Custom) {
      return currentSubject.length > 0 && currentBody.length > 0;
    }
    if (
      currentType === BulkEmailType.FollowerGenericUpdate ||
      currentType === BulkEmailType.FollowerCustomWithHome ||
      currentType === BulkEmailType.FollowerNowOnPremarket ||
      currentType === BulkEmailType.FollowerPhotosPublished ||
      currentType === BulkEmailType.FollowerViewingScheduled ||
      currentType === BulkEmailType.FollowerRecommendAHome ||
      currentType === BulkEmailType.MarketingSoldWithoutPrice ||
      currentType === BulkEmailType.MarketingSoldWithPrice ||
      currentType === BulkEmailType.FollowerSoldUpdate
    ) {
      return currentBody.length > 0 && !!lookupData?.home;
    }
    if (
      currentType === BulkEmailType.LeadTemplateOnboarding ||
      currentType === BulkEmailType.LeadTemplateInterest ||
      currentType === BulkEmailType.LeadTemplateInterest2 ||
      currentType === BulkEmailType.LeadTemplateInterest3 ||
      currentType === BulkEmailType.LeadTemplateHidden ||
      currentType === BulkEmailType.LeadTemplateFollower ||
      currentType === BulkEmailType.LeadTemplateTagYourHome ||
      currentType === BulkEmailType.LeadTemplateBecomeFollower ||
      currentType === BulkEmailType.LeadTemplateFloorPlan ||
      currentType === BulkEmailType.LeadTemplateUploadPhotos ||
      currentType === BulkEmailType.LeadTemplateCampaign ||
      currentType === BulkEmailType.AgreementContractActivation
    ) {
      return true;
    }
    if (currentType === BulkEmailType.CampaignFollowers_2024Mar) {
      return true;
    }

    return false;
  }, [currentType, currentSubject, currentBody, lookupData?.home]);

  const includeHomeLookup = () => {
    if (
      currentType === BulkEmailType.Custom ||
      currentType === BulkEmailType.CampaignFollowers_2024Mar ||
      currentType === BulkEmailType.LeadTemplateOnboarding ||
      currentType === BulkEmailType.LeadTemplateInterest ||
      currentType === BulkEmailType.LeadTemplateInterest2 ||
      currentType === BulkEmailType.LeadTemplateSms ||
      currentType === BulkEmailType.LeadTemplateInterest3 ||
      currentType === BulkEmailType.LeadTemplateHidden ||
      currentType === BulkEmailType.LeadTemplateFollower ||
      currentType === BulkEmailType.LeadTemplateTagYourHome ||
      currentType === BulkEmailType.LeadTemplateBecomeFollower ||
      currentType === BulkEmailType.LeadTemplateFloorPlan ||
      currentType === BulkEmailType.LeadTemplateUploadPhotos ||
      currentType === BulkEmailType.LeadTemplateCampaign
    ) {
      return false;
    }

    return true;
  };

  const waitForHomeLookup = () => {
    if (
      currentType === BulkEmailType.Custom ||
      currentType === BulkEmailType.CampaignFollowers_2024Mar ||
      currentType === BulkEmailType.LeadTemplateInterest ||
      currentType === BulkEmailType.LeadTemplateInterest2 ||
      currentType === BulkEmailType.LeadTemplateOnboarding ||
      currentType === BulkEmailType.LeadTemplateHidden ||
      currentType === BulkEmailType.LeadTemplateFollower
    ) {
      return false;
    }

    return !lookupData?.home;
  };

  const renderEditStep = () => {
    if (currentType === BulkEmailType.CampaignFollowers_2024Mar) {
      return <Box mt={4}>{L.emailDialog.nothingToEdit}</Box>;
    }

    return (
      <>
        <Box mt={4}>
          <TextField
            label={L.emailDialog.subject}
            fullWidth
            value={currentSubject}
            onChange={ev => setModifiedSubject(ev.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setModifiedSubject(undefined);
                  }}
                >
                  <ClearOutlined />
                </IconButton>
              ),
            }}
          />
        </Box>
        {includeHomeLookup() && (
          <>
            <HomeLookup
              homeId={currentHomeId}
              loading={lookupLoading}
              streetAddress={
                lookupData?.home?.address?.publicStreetAddress ?? undefined
              }
              onChangeHomeId={updatedHomeId => setModifiedHomeId(updatedHomeId)}
            />
            {lookupWarning && <Alert severity="warning">{lookupWarning}</Alert>}
          </>
        )}
        <Box mt={2} mb={2}>
          <TextField
            label={L.emailDialog.body}
            multiline
            maxRows={10}
            minRows={5}
            value={currentBody}
            fullWidth
            onChange={ev => setBody(ev.target.value)}
            disabled={waitForHomeLookup()}
          />
        </Box>
      </>
    );
  };

  const step1 = {
    label: L.emailDialog.step1,
    content: (
      <Stack spacing={2} mt={4} mb={2}>
        <RadioGroup
          aria-label={L.emailDialog.selection}
          name={L.emailDialog.selection}
          value={recipientSubset}
          onChange={evt =>
            setRecipientSubset(evt.target.value as 'list' | 'filtered')
          }
        >
          <FormControlLabel
            value="list"
            control={<Radio color="primary" />}
            label={L.emailDialog.selected}
            labelPlacement="end"
            disabled={recipients?.length === 0}
          />
          <FormControlLabel
            value="filtered"
            control={<Radio color="primary" />}
            label={L.emailDialog.filtered}
            labelPlacement="end"
            disabled={userFilterCount <= 0}
          />
        </RadioGroup>
        <Accordion
          sx={{
            bgcolor: grey.A200,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {`${L.emailDialog.numberOfRecepients}: ${recipientCount}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {recipientSubset === 'list'
                ? (recipients?.map(({ email }) => email).join(', ') ?? '')
                : L.emailDialog.accordingToFilter}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {recipientCount > WARNING_LIMIT && (
          <Alert severity="warning">
            {L.emailDialog.warning.replace('{warning}', `${WARNING_LIMIT}`)}
          </Alert>
        )}
      </Stack>
    ),
    disableNext: recipientCount === 0,
  };
  const step2 = {
    label: L.emailDialog.step2,
    content: (
      <Box mt={4}>
        <RadioGroup
          aria-label="Val av mall"
          name="Val av mall"
          value={currentType}
          onChange={evt => {
            const newCategory = evt.target.value as BulkEmailType;

            setSelectedType(newCategory);
          }}
          row
        >
          {typeDatas.map(template => (
            <FormControlLabel
              key={template.type}
              value={template.type}
              control={<Radio color="primary" />}
              label={template.description}
              labelPlacement="end"
            />
          ))}
        </RadioGroup>
      </Box>
    ),
    disableNext: false,
  };
  const step3 = {
    label: L.emailDialog.step3,
    content: renderEditStep(),
    disableNext: !isValidEmail,
    onBack: () => {
      setModifiedHomeId(undefined);
      setLookupWarning('');
    },
  };
  const step4 = {
    label: L.emailDialog.step4,
    content: (
      <Box mt={4}>
        <EmailPreview unsubscribeType={selectedUnsubscribeType} />
      </Box>
    ),
    disableNext: false,
  };

  return (
    <>
      <WizardDialog
        open={open}
        title={L.emailDialog.title}
        steps={
          skipFirstTwoSteps ? [step3, step4] : [step1, step2, step3, step4]
        }
        onCompleted={handleSendMail}
        onClose={handleClose}
        closeOnClickOutside={false}
      />
      <PopupAlert />
    </>
  );
};

export default EmailDialog;
