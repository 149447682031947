import { sv as locale } from '@norban/locale';

import {
  HomeState,
  SalesProcessStatus,
  TenureType,
} from '../generated/backend/graphql';

const L = locale.backoffice.enumerations;

export const translateStateShort = (state: HomeState) =>
  L.HomeStateShort[state] || '-';

export const translateTenure = (tenure: TenureType) => L.Tenure[tenure] || '-';

export const translateSalesProcessStatus = (status: SalesProcessStatus) =>
  L.SalesProcessStatus[status] || '-';
