export const UPDATE_FOLLOWER_FILTER = 'UPDATE_FOLLOWER_FILTER';

const actionUpdateFilter = filter => ({
  type: UPDATE_FOLLOWER_FILTER,
  filter,
});

export const updateFilter = (dispatch, filter) => {
  dispatch(actionUpdateFilter(filter));
};
