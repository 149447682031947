import {
  Table,
  TableBody,
  TextField,
  Grid,
  Box,
  TableRow,
  TableCell,
  useMediaQuery,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { makeStyles, useTheme } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  storeFilterSearchQuery,
  storeOrder,
  storeOrderBy,
} from '../../../actions/userActions';
import SpinnerRow from '../../../components/SpinnerRow';
import { useGlobalStateWithDelayedCallback } from '../../../hooks/useGlobalStateWithDelayedCallback';
import { PAGE_COUNT } from '../config';
import useFilterUsers from '../hooks/useFilterUsers';

import UserRow from './UserRow';
import UserTableFilter from './UserTableFilter';
import UserTableHead from './UserTableHead';

const useStyles = makeStyles(() => ({
  table: {
    borderCollapse: 'separate',
    tableLayout: 'fixed',
    width: '100%',
  },
}));

const UserTable = ({
  loading,
  checkboxes: { toggle, toggleAll, clearAll, isChecked, isAllChecked },
  onChangePage,
  onChangeUser,
  onEditUserLabels,
  onFilterUsers,
  page,
  rootAreas,
  totalCount,
  userCount,
  userFilter,
  userLabels,
  users,
}) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useGlobalStateWithDelayedCallback(
    '',
    dispatch,
    storeFilterSearchQuery,
    300,
  );
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const L = locale.backoffice;

  const filteredSortedUsers = useFilterUsers(
    users,
    userFilter.order,
    userFilter.orderBy,
  );

  const userLabelMap = useMemo(
    () =>
      userLabels?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur.label,
        }),
        {},
      ),
    [userLabels],
  );

  const renderPageInfo = () => {
    if (loading) return '';

    const lowerPageBound = (page - 1) * PAGE_COUNT;
    const upperPageBound = Math.min(totalCount, page * PAGE_COUNT);

    return `(${lowerPageBound} - ${upperPageBound}) av ${totalCount}`;
  };

  return (
    <>
      <UserTableFilter
        onFilterUsers={onFilterUsers}
        rootAreas={rootAreas}
        userFilter={userFilter}
        userLabels={userLabels}
      />
      <Grid container pt={2}>
        <Grid item sm={6}>
          <Box mt={1} mb={1} ml={2} mr={2}>
            <TextField
              fullWidth
              label={L.search}
              size="small"
              value={searchQuery}
              onChange={evt => setSearchQuery(evt.target.value)}
            />
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" height="100%">
            <Box display="flex" alignItems="center" ml={2} mr={2}>
              {!!userCount && (
                <Pagination
                  count={Math.floor(totalCount / PAGE_COUNT) + 1}
                  onChange={(evt, value) => onChangePage(value)}
                  page={page}
                />
              )}
              {renderPageInfo()}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Table className={classes.table}>
          <UserTableHead
            order={userFilter.order}
            orderBy={userFilter.orderBy}
            onClick={category => {
              storeOrder(
                dispatch,
                userFilter.order === 'asc' && userFilter.orderBy === category
                  ? 'desc'
                  : 'asc',
              );
              storeOrderBy(dispatch, category);
            }}
            checkbox={{ toggleAll, clearAll, isAllChecked }}
          />
          {!loading && (
            <TableBody>
              {filteredSortedUsers?.map(u => (
                <UserRow
                  key={u.id}
                  user={u}
                  userLabelMap={userLabelMap}
                  checked={isChecked(u)}
                  onEditUserLabels={onEditUserLabels}
                  onChangeUser={onChangeUser}
                  onToggle={toggle}
                />
              ))}
            </TableBody>
          )}
          {loading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={mobile ? 6 : 9}>
                  <SpinnerRow />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </>
  );
};

export default UserTable;
