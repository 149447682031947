// Duplicated from GraphQL generated types
enum HomeState {
  Hidden = 'HIDDEN',
  Invalid = 'INVALID',
  None = 'NONE',
  OpenMarket = 'OPEN_MARKET',
  Premarket = 'PREMARKET',
  PremarketLight = 'PREMARKET_LIGHT',
  Sold = 'SOLD',
  Withdrawn = 'WITHDRAWN',
}

export function isDisclosed(state: HomeState) {
  // Is this a home with disclosed details (I.E. we are allowed to show exact address etc)
  return (
    state === HomeState.Premarket ||
    state === HomeState.OpenMarket ||
    state === HomeState.Sold
  );
}

export function isPublic(state: HomeState) {
  return isDisclosed(state) || state === HomeState.PremarketLight;
}
