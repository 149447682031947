import { useQuery, useSubscription } from '@apollo/client';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HistoryIcon from '@mui/icons-material/History';
import HomeIcon from '@mui/icons-material/Home';
import ImageIcon from '@mui/icons-material/Image';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tab, Tabs, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Stack, Theme } from '@mui/system';
import { sv as locale } from '@norban/locale';
import { isValidID } from '@norban/utils/dist';
import React, { useMemo, useState } from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router';
import { Link, Switch } from 'react-router-dom';

import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import UserAndHomeHeader from '../../components/UserAndHomeHeader';
import {
  BofHomeBaseDocument,
  BofHomeBaseHomeFragment,
  BofHomeUpdatedDocument,
  BofHomeUpdatedSubscription,
} from '../../generated/backend/graphql';
import { useBackendSubscriptionClient } from '../../hooks/useBackendClient';
import { isabelline, theme } from '../../theme';

import HomeCrmAndLog from './components/HomeCrmAndLog';
import HomeListing from './components/HomeListing';
import HomeOverview from './components/HomeOverview';
import HomeProspects from './components/HomeProspects';
import HomeSalesProcess from './components/HomeSalesProcess';
import Timeline from './components/Timeline';

const L = locale.backoffice.homePage;

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    backgroundColor: isabelline,
    position: 'sticky',
    top: 64,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },
  },
  tab: {
    minWidth: 'auto',
  },
}));

type Props = {
  home: BofHomeBaseHomeFragment;
  tab?: string;
  basePath: string;
};

const Home = ({ home, tab = undefined, basePath }: Props) => {
  const subscriptionClient = useBackendSubscriptionClient();

  const [subscriptionTimeline, setSubscriptionTimeline] =
    useState<BofHomeUpdatedSubscription>();

  useSubscription(BofHomeUpdatedDocument, {
    client: subscriptionClient,
    variables: { homeId: home.id },
    onSubscriptionData: opt => {
      setSubscriptionTimeline(opt?.subscriptionData?.data);
    },
  });

  const styles = useStyles();

  const tabs: {
    subPath: string;
    title: string;
    icon: React.ReactNode;
    component: React.ReactNode;
  }[] = useMemo(
    () => [
      {
        subPath: `overview`,
        title: L.tabs.overview,
        icon: <VisibilityIcon />,
        component: (
          <HomeOverview homeId={home.id} userId={home.userId ?? undefined} />
        ),
      },
      {
        subPath: `crm-log`,
        title: L.tabs.crmAndLog,
        icon: <HistoryIcon />,
        component: (
          <HomeCrmAndLog homeId={home.id} userId={home.userId ?? undefined} />
        ),
      },
      {
        subPath: `sales-process`,
        title: L.tabs.salesProcess,
        icon: <HomeIcon />,
        component: (
          <HomeSalesProcess
            homeId={home.id}
            userId={home.userId ?? undefined}
          />
        ),
      },
      {
        icon: <DirectionsRunIcon />,
        title: L.tabs.interest,
        subPath: `prospects`,
        component: <HomeProspects homeId={home.id} />,
      },
      {
        subPath: 'listing',
        title: L.tabs.listing,
        icon: <ImageIcon />,
        component: <HomeListing homeId={home.id} />,
      },
      {
        icon: <ListAltIcon />,
        title: L.tabs.timeline,
        subPath: `timeline`,
        component: (
          <Timeline
            userId={home.userId ?? undefined}
            subscription={subscriptionTimeline}
            onSubscriptionHandled={() => setSubscriptionTimeline(undefined)}
          />
        ),
      },
    ],
    [home.id, home.userId, subscriptionTimeline],
  );

  const smallDisplay = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction="column" spacing={2}>
      <UserAndHomeHeader
        homeId={home.id}
        userId={home.userId ?? undefined}
        type="home"
      />
      <Tabs className={styles.tabs} variant="scrollable" value={tab}>
        {tabs.map(({ subPath, title, icon }) => (
          <Tab
            className={styles.tab}
            key={subPath}
            value={subPath}
            component={Link}
            to={`${basePath}/${subPath}`}
            label={smallDisplay ? icon : title}
          />
        ))}
      </Tabs>
      <Box>
        <Switch>
          {tabs.map(({ subPath, component }) => (
            <Route key={subPath} path={`${basePath}/${subPath}`}>
              {component}
            </Route>
          ))}
          <Route>
            <Redirect to={`${basePath}/${tabs[0]?.subPath}`} />
          </Route>
        </Switch>
      </Box>
    </Stack>
  );
};

const HomeRouter = ({ homeId }: { homeId: string }) => {
  const { url } = useRouteMatch();

  const validID = isValidID(homeId);

  const { data, loading, error } = useQuery(BofHomeBaseDocument, {
    variables: { homeId },
    skip: !validID,
  });

  if (!validID) {
    return (
      <div>
        <h3>{L.invalidHomeId}</h3>
      </div>
    );
  }

  if (loading) {
    return <QueryLoading />;
  }

  if (error || !data?.home) {
    return <QueryError error={error} data={data} />;
  }

  return (
    <Switch>
      <Route path={url} exact>
        <Home home={data.home} basePath={url} />
      </Route>
      <Route
        path={`${url}/:tab`}
        render={({
          match: {
            params: { tab },
          },
        }) => <Home home={data.home} tab={tab} basePath={url} />}
      />
    </Switch>
  );
};

export default HomeRouter;
