import { useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import PageHeader from '../../components/PageHeader';
import QueryError from '../../components/QueryError';
import QueryLoading from '../../components/QueryLoading';
import SpinnerRow from '../../components/SpinnerRow';
import {
  BofAreasDocument,
  BofFollowerCountDocument,
  BofFollowersDocument,
  FollowerOrder,
  FollowerUniqueType,
} from '../../generated/backend/graphql';
import { defaultState as defaultFollowerFilterState } from '../../reducers/followerFilter';
import { findAreaByIds, flattenAreas } from '../../utils/area';

import FollowersTable, {
  FollowerFilterType,
} from './components/FollowersTable';
import SearchForm, { MAX_LIMIT_FOLLOWERS } from './SearchForm';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    overflowX: 'auto',
  },
}));

const Followers = () => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [freeSearchTerm, setFreeSearchTerm] = useState('');

  const L = locale.backoffice.followers;

  const filter = useSelector<
    { followerFilter: FollowerFilterType },
    FollowerFilterType
  >(state => ({
    ...defaultFollowerFilterState,
    ...state.followerFilter,
  }));

  const {
    loading: areasLoading,
    error: areasError,
    data: areasData,
  } = useQuery(BofAreasDocument);

  const { rootAreas } = areasData ?? { rootAreas: [] };

  const areaIds = useMemo(
    () =>
      findAreaByIds(filter.areaIds, rootAreas ?? [])
        .flatMap(area => flattenAreas(area))
        .map(({ id }) => id),
    [filter.areaIds, rootAreas],
  );

  const buildFilter = (
    withPagination: boolean,
    currentPage: number = page,
  ) => ({
    areaIds: filter.areasEnabled ? areaIds || [] : undefined,
    minRooms: filter.roomsRangeEnabled ? filter.roomsRange[0] : undefined,
    maxRooms: filter.roomsRangeEnabled ? filter.roomsRange[1] : undefined,
    minLivingArea: filter.livingAreaRangeEnabled
      ? filter.livingAreaRange[0]
      : undefined,
    maxLivingArea: filter.livingAreaRangeEnabled
      ? filter.livingAreaRange[1]
      : undefined,
    unique: filter.unique,
    active: filter.activeEnabled ? filter.active : undefined,
    homeStates: filter.homeStatesEnabled ? filter.homeStates : undefined,
    latestCount: withPagination ? MAX_LIMIT_FOLLOWERS : undefined,
    offset: withPagination
      ? (currentPage - 1) * MAX_LIMIT_FOLLOWERS
      : undefined,
    excludeHomeId: filter.homeId,
  });

  // Get the total count to set the pagination
  const { data: followerCountData } = useQuery(BofFollowerCountDocument, {
    variables: {
      followerOptions: {
        filter: buildFilter(false),
      },
    },
    skip: areasLoading || !areasData,
  });
  const totalCount = followerCountData?.followerCount || 0;
  const pageCount = Math.max(
    1,
    Math.ceil((totalCount || 0) / MAX_LIMIT_FOLLOWERS),
  );
  const currentPage = Math.min(page, pageCount);

  // Use pagination data to fetch the actual followers
  const {
    loading: followersLoading,
    error: followersError,
    data: followersData,
  } = useQuery(BofFollowersDocument, {
    variables: {
      followerOptions: {
        filter: buildFilter(true, currentPage),
        orderBy: FollowerOrder.UpdatedAt,
      },
    },
    skip: areasLoading || !areasData,
  });

  const { followers } = followersData || {};

  type FollowersExceptUndefined = Exclude<typeof followers, undefined>;

  type SearchProperties =
    | keyof FollowersExceptUndefined[0]
    | 'name'
    | 'email'
    | 'phone';
  const searchProperties: SearchProperties[] = [
    'createdAt',
    'name',
    'email',
    'phone',
    'id',
    'homeId',
  ];

  // Works since the field in the table we want to search are the same property on both user & home objects
  const includesSearchTerm = (toSearch: string) =>
    toSearch && toSearch.toLowerCase().includes(freeSearchTerm.toLowerCase());

  const followersFiltered = followers
    ?.map(follower => ({
      ...follower,
      name: follower.user?.name,
      email: follower.user?.email,
      phone: follower.user?.phone,
    }))
    .filter(follower => {
      if (!freeSearchTerm?.length) {
        return true;
      }
      return searchProperties.some((searchProperty: keyof typeof follower) => {
        const value = follower[searchProperty];
        if (typeof value !== 'string') {
          return false;
        }

        return includesSearchTerm(value);
      });
    });

  const activeString = useMemo(() => {
    if (!filter.activeEnabled || totalCount === 0) {
      return '';
    }
    if (filter.active) {
      if (totalCount === 1) {
        return `${L.activeSingular.toLowerCase()} `;
      }
      return `${L.activePlural.toLowerCase()} `;
    }

    if (totalCount === 1) {
      return `${L.inactiveSingular.toLowerCase()} `;
    }
    return `${L.inactivePlural.toLowerCase()} `;
  }, [
    L.activePlural,
    L.activeSingular,
    L.inactivePlural,
    L.inactiveSingular,
    filter.active,
    filter.activeEnabled,
    totalCount,
  ]);

  const chunkString = useMemo(() => {
    if (totalCount === 0) {
      return '';
    }
    return `(${(currentPage - 1) * MAX_LIMIT_FOLLOWERS + 1} - ${Math.min(currentPage * MAX_LIMIT_FOLLOWERS, totalCount)}) av `;
  }, [currentPage, totalCount]);

  const unitString = useMemo(() => {
    if (totalCount === 1) {
      if (filter.unique === FollowerUniqueType.None) {
        return L.following.toLowerCase();
      }
      return L.follower.toLowerCase();
    }
    if (filter.unique === FollowerUniqueType.None) {
      return L.followings.toLowerCase();
    }
    return L.followers.toLowerCase();
  }, [
    L.follower,
    L.followers,
    L.following,
    L.followings,
    filter.unique,
    totalCount,
  ]);

  if (areasLoading) {
    return <QueryLoading />;
  }

  if (areasError) {
    return <QueryError error={areasError} data={areasError} />;
  }

  return (
    <>
      <PageHeader
        title={L.followers}
        buttons={undefined}
        links={undefined}
        overline={undefined}
      />

      <Paper>
        <SearchForm filter={filter} rootAreas={rootAreas} />
        <Box my={1}>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={(_evt, page) => setPage(page)}
          />
        </Box>
        <Divider />
        {followersLoading && <SpinnerRow />}
        {!followersLoading && (
          <Stack mt={2} spacing={2} ml={2}>
            <Typography>
              {chunkString}
              {totalCount !== undefined ? totalCount : '-'} {activeString}
              {unitString}
            </Typography>

            <Box pb={2}>
              <TextField
                size="small"
                type="search"
                placeholder="Sök bland resultat"
                value={freeSearchTerm || ''}
                onChange={e => setFreeSearchTerm(e.target.value)}
              />
            </Box>
          </Stack>
        )}
        {!followersLoading && (
          <Paper className={classes.paper}>
            <FollowersTable
              filter={filter}
              followers={followersFiltered ?? []}
              followersError={!!followersError}
              followersLoading={followersLoading}
            />
          </Paper>
        )}
      </Paper>
    </>
  );
};

export default Followers;
