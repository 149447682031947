import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import UserAutocomplete from '../../../components/UserAutocomplete';

const LeadTemplatesAssignedAgentPicker = ({
  selectedAgentId,
  onSelectAgentId,
}: {
  selectedAgentId: string | null;
  onSelectAgentId: (id: string | null) => void;
}) => {
  return (
    <Box mt={2}>
      <Grid container>
        <Grid item xs={12} sm={12} md={9} lg={6}>
          <UserAutocomplete
            defaultLimit={300}
            fullWidth
            limitToUserRoles={['admin']}
            onSelectedUserIdChanged={id => onSelectAgentId(id)}
            selectedUserId={selectedAgentId}
            sortAlphabetically
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadTemplatesAssignedAgentPicker;
