export function formatDate(date: Date | string | undefined | null) {
  let aDate;
  if (date instanceof Date) {
    aDate = date;
  }
  if (typeof date === 'string') {
    aDate = new Date(date);
  }

  return aDate instanceof Date
    ? new Intl.DateTimeFormat('sv-SE', {
        year: 'numeric',
        day: 'numeric',
        month: 'short',
      }).format(aDate)
    : '-';
}
