import { useCallback } from 'react';
import { Dispatch } from 'redux';

import useStateWithDelayedCallback from './useStateWithDelayedCallback';

export const useGlobalStateWithDelayedCallback = <T>(
  initialValue: T,
  dispatch: Dispatch,
  action: (dispatch: Dispatch, value: T) => void,
  delay: number,
): [T, (arg: T) => void, (arg: T) => void] => {
  // Dispatches a Redux action after a delay of `delay` milliseconds
  return useStateWithDelayedCallback(
    initialValue,
    useCallback(value => action(dispatch, value), [action, dispatch]),
    delay,
  );
};
