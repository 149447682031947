import { Card, CardContent, CardHeader } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React from 'react';

import Select from '../../../../components/form/Select';
import { BofHomeProfileHomeFragment } from '../../../../generated/backend/graphql';
import { DispatchType } from '../../hooks/useHomeProfileReducer';

type Props = {
  home: BofHomeProfileHomeFragment;
  dispatch: DispatchType;
};

const HomeStateCard = ({ home, dispatch }: Props) => {
  const L = locale.backoffice;

  return (
    <Card>
      <CardHeader title="Annonsstatus" />
      <CardContent>
        <Select
          label={L.homeProfile.homeState}
          value={home.state}
          name="state"
          dispatch={dispatch}
          options={L.enumerations.HomeState}
          includeEmpty={false}
          sortOptions={false}
        />
      </CardContent>
    </Card>
  );
};

export default HomeStateCard;
