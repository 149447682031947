import { Dispatch } from 'redux';

import { HomeFilter, UPDATE_HOME_FILTER } from '../reducers/homeFilter';

const actionUpdateFilter = (filter: HomeFilter) => ({
  type: UPDATE_HOME_FILTER,
  filter,
});

export const updateFilter = (dispatch: Dispatch, filter: HomeFilter) => {
  dispatch(actionUpdateFilter(filter));
};
