import {
  Box,
  FilledTextFieldProps,
  MenuItem,
  OutlinedTextFieldProps,
  Select,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { sv as locale } from '@norban/locale';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import React, { useCallback, useState } from 'react';

const DateSelector = ({
  quickSelect = false,
  disabled = false,
  startDate,
  startLabel,
  style = undefined,
  endLabel,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  isTime = false,
}: {
  quickSelect?: boolean;
  disabled?: boolean;
  startDate: Date | string | null;
  startLabel: string;
  style?: React.CSSProperties;
  endLabel: string;
  onChangeStartDate: (date: Date | null) => void;
  endDate: Date | string | null;
  onChangeEndDate: (date: Date | null) => void;
  isTime?: boolean;
}) => {
  const L = locale.backoffice.followerRanges;
  const [quickRange, setQuickRange] = useState('undefined');

  const useStyles = makeStyles<Theme>(theme => ({
    item: {
      ...style,
      marginLeft: theme.spacing(1),
    },
    picker: {
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      '& input': {
        padding: '8.5px 14px',
      },
    },
  }));
  const classes = useStyles();
  const timeParams = {
    inputFormat: 'yyyy-MM-dd HH:mm',
    mask: '____-__-__ __:__',
  };
  const dateParams = {
    inputFormat: 'yyyy-MM-dd',
    mask: '____-__-__',
  };
  const params = {
    ampm: false,
    className: classes.item,
    disabled,
    renderInput: (
      params: React.JSX.IntrinsicAttributes & {
        variant?: TextFieldVariants | undefined;
      } & Omit<
          | FilledTextFieldProps
          | OutlinedTextFieldProps
          | StandardTextFieldProps,
          'variant'
        >,
    ) => (
      <Box sx={{ display: 'inline-block' }} ml={2} mt={1} mb={1}>
        <TextField size="small" {...params} />
      </Box>
    ),
  };
  const startParams = {
    ...params,
    label: startLabel,
    value: typeof startDate === 'string' ? new Date(startDate) : startDate,
    onChange: (date: Date | null) => {
      setQuickRange('undefined');
      onChangeStartDate(date);
    },
  };
  const endParams = {
    ...params,
    label: endLabel,
    value: typeof endDate === 'string' ? new Date(endDate) : endDate,
    onChange: (date: Date | null) => {
      setQuickRange('undefined');
      onChangeEndDate(date);
    },
  };

  const QuickSelect = useCallback(() => {
    if (!quickSelect) {
      return null;
    }

    return (
      <Box my={1} mr={1}>
        <Select
          autoWidth
          disabled={disabled}
          value={quickRange}
          size="small"
          onChange={event => {
            const now = new Date();

            setQuickRange(event.target.value);
            switch (event.target.value) {
              case 'today':
                onChangeStartDate(startOfDay(now));
                onChangeEndDate(endOfDay(now));
                break;
              case 'yesterday':
                onChangeStartDate(startOfDay(subDays(now, 1)));
                onChangeEndDate(endOfDay(subDays(now, 1)));
                break;
              case 'pastTwoDays':
                onChangeStartDate(startOfDay(subDays(now, 2)));
                onChangeEndDate(endOfDay(subDays(now, 1)));
                break;
              case 'pastThreeDays':
                onChangeStartDate(startOfDay(subDays(now, 3)));
                onChangeEndDate(endOfDay(subDays(now, 1)));
                break;
              // 'aWeekSince':
              default:
                onChangeStartDate(startOfDay(subDays(now, 7)));
                onChangeEndDate(endOfDay(subDays(now, 1)));
                break;
            }
          }}
          displayEmpty
        >
          <MenuItem value="undefined" disabled>
            Välj
          </MenuItem>
          <MenuItem value="today">{L.today}</MenuItem>
          <MenuItem value="yesterday">{L.yesterday}</MenuItem>
          <MenuItem value="pastTwoDays">{L.pastTwoDays}</MenuItem>
          <MenuItem value="pastThreeDays">{L.pastThreeDays}</MenuItem>
          <MenuItem value="aWeekSince">{L.aWeekSince}</MenuItem>
        </Select>
      </Box>
    );
  }, [
    L.aWeekSince,
    L.pastThreeDays,
    L.pastTwoDays,
    L.today,
    L.yesterday,
    disabled,
    onChangeEndDate,
    onChangeStartDate,
    quickRange,
    quickSelect,
  ]);

  if (isTime) {
    return (
      <>
        <QuickSelect />
        <DesktopDateTimePicker
          {...startParams}
          {...timeParams}
          className={classes.picker}
        />
        <DesktopDateTimePicker
          {...endParams}
          {...timeParams}
          className={classes.picker}
        />
      </>
    );
  }

  return (
    <>
      <QuickSelect />
      <Box mb={1}>
        <DesktopDatePicker
          {...startParams}
          {...dateParams}
          className={classes.picker}
        />
      </Box>
      <Box mb={1}>
        <DesktopDatePicker
          {...endParams}
          {...dateParams}
          className={classes.picker}
        />
      </Box>
    </>
  );
};

export default DateSelector;
